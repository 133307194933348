<template>
    <div>

        <RecordDialog v-if="showRecordDialog" v-bind="recordDialogParameters"
                      v-on:hidden="showRecordDialog = false"
                      v-on:ok="fetchData"/>

        <div class="card st-framework-card">

            <div class="card-header">
                <table style="border-collapse: collapse; width: 100%;">
                    <tr>
                        <td style="margin: 0; padding: 0 0 0 1rem; width: 50px;">
                            <font-awesome-icon :icon="icon" size="sm"/>
                        </td>
                        <td style="text-align: center;">
                            {{ $i18n.tc('translations.' + name, 2) }}
                        </td>
                        <td style="width: 50px;"/>
                    </tr>
                </table>
            </div>

            <div class="card-body" v-if="pageLoaded">
                <div class="card-columns">

                    <!-- PARAMETERS -->

                    <div class="card">
                        <div class="card-header">
                            <b-form inline>
                                {{ $i18n.t('translations.Parameters') }}
                                <template v-for="(action,actionIndex) in actions.updateParameters">
                                    <ButtonAction :key="action.name+'-'+actionIndex" :action="action"
                                                  v-on:update-parameters="updateParameters"/>
                                </template>
                            </b-form>
                        </div>
                        <div class="card-body st-fadein-content" v-if="settings.parameters">
                            <table class="table table-borderless table-sm" style="margin-bottom: 0">
                                <tbody>
                                <tr>
                                    <td class="p-0" style="width: 250px;"><b>{{ $i18n.t('translations.Application') }}</b></td>
                                    <td class="p-0">{{ settings.parameters.application }}</td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="pt-3"/>
                                </tr>
                                <tr>
                                    <td class="p-0" style="width: 250px;"><b>{{ $i18n.t('translations.Scanner App Version') }}</b></td>
                                    <td class="p-0">{{ settings.parameters.scanner_app_version }}</td>
                                </tr>
                                <tr>
                                    <td class="p-0" style="width: 250px;"><b>{{ $i18n.t('translations.SketchUp Plugin Version') }}</b></td>
                                    <td class="p-0">{{ settings.parameters.sketchup_plugin_version }}</td>
                                </tr>
                                <tr>
                                    <td class="p-0" style="width: 250px;"><b>{{ $i18n.t('translations.Punch Plates Utility Version') }}</b></td>
                                    <td class="p-0">{{ settings.parameters.punch_plates_utility_version }}</td>
                                </tr>
                                <tr>
                                    <td class="p-0" style="width: 250px;"><b>{{ $i18n.t('translations.Punch Plates 2 Utility Version') }}</b></td>
                                    <td class="p-0">{{ settings.parameters.punch_plates2_utility_version }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <!-- SCHEDULER -->

                    <div class="card">
                        <div class="card-header">
                            <b-form inline>
                                {{ $i18n.t('translations.Scheduler') }}
                                <template v-for="(action,actionIndex) in actions.updateScheduler">
                                    <ButtonAction :key="action.name+'-'+actionIndex" :action="action"
                                                  v-on:update-scheduler="updateScheduler"/>
                                </template>
                            </b-form>
                        </div>
                        <div class="card-body st-fadein-content" v-if="settings.scheduler">
                            <table class="table table-borderless table-sm" style="margin-bottom: 0">
                                <tbody>
                                <tr>
                                    <td class="p-0" style="width: 250px;"><b>{{ $i18n.t('translations.Data Export') }}</b></td>
                                    <td class="p-0">
                                        <template v-if="settings.scheduler.export === 1">
                                            {{ (settings.export && settings.export.last_run) ? settings.export.last_run : 'active' }}
                                        </template>
                                        <template v-else>
                                            disabled
                                        </template>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0" style="width: 250px;"><b>{{ $i18n.tc('translations.Reminder', 2) }}</b></td>
                                    <td class="p-0">
                                        <template v-if="settings.scheduler.reminders === 1">
                                            {{ (settings.reminders && settings.reminders.last_run) ? settings.reminders.last_run : 'active' }}
                                        </template>
                                        <template v-else>
                                            disabled
                                        </template>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <!-- SKETCHUP -->

                    <div class="card">
                        <div class="card-header">
                            <b-form inline>
                                {{ $i18n.tc('translations.SketchUp', 1) }} {{ $i18n.tc('translations.Assembly', 1) }}
                                <template v-for="(action,actionIndex) in actions.updateSketchUp">
                                    <ButtonAction :key="action.name+'-'+actionIndex" :action="action"
                                                  v-on:update-sketchup="updateSketchUp"/>
                                </template>
                            </b-form>
                        </div>
                        <div class="card-body st-fadein-content" v-if="settings.sketchUp">
                            <table class="table table-borderless table-sm" style="margin-bottom: 0">
                                <tbody>
                                <tr>
                                    <td class="p-0" style="width: 250px;"><b>{{ $i18n.tc('translations.Dimensions Tag', 1) }}</b></td>
                                    <td class="p-0">{{ settings.sketchUp.assembly_tag }}</td>
                                </tr>
                                <tr>
                                    <td class="p-0" style="width: 250px;"><b>{{ $i18n.tc('translations.Definition', 2) }}</b></td>
                                    <td class="p-0">{{ settings.sketchUp.assembly_definitions }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <!-- ESTIMATE DESCRIPTION -->

                    <div class="card">
                        <div class="card-header">
                            <b-form inline>
                                {{ $i18n.tc('translations.Estimate', 1) }} {{ $i18n.tc('translations.Introduction', 1) }}
                                <template v-for="(action,actionIndex) in actions.updateDefaults">
                                    <ButtonAction :key="action.name+'-'+actionIndex" :action="action"
                                                  v-on:update-defaults="updateEstimate"/>
                                </template>
                            </b-form>
                        </div>
                        <div class="card-body st-fadein-content" v-if="settings.defaults">
                            <p v-html="settings.defaults.estimate_introduction"/>
                        </div>
                        <div class="card-header">
                            {{ $i18n.tc('translations.Estimate', 1) }} {{ $i18n.tc('translations.Description', 1) }}
                        </div>
                        <div class="card-body st-fadein-content" v-if="settings.defaults">
                            <p v-html="settings.defaults.estimate_description"/>
                        </div>
                    </div>

                    <!-- QUOTE DESCRIPTION -->

                    <div class="card">
                        <div class="card-header">
                            <b-form inline>
                                {{ $i18n.tc('translations.Quote', 1) }} {{ $i18n.tc('translations.Introduction', 1) }}
                                <template v-for="(action,actionIndex) in actions.updateDefaults">
                                    <ButtonAction :key="action.name+'-'+actionIndex" :action="action"
                                                  v-on:update-defaults="updateQuote"/>
                                </template>
                            </b-form>
                        </div>
                        <div class="card-body st-fadein-content" v-if="settings.defaults">
                            <p v-html="settings.defaults.quote_introduction"/>
                        </div>
                        <div class="card-header">
                            {{ $i18n.tc('translations.Quote', 1) }} {{ $i18n.tc('translations.Description', 1) }}
                        </div>
                        <div class="card-body st-fadein-content" v-if="settings.defaults">
                            <p v-html="settings.defaults.quote_description"/>
                        </div>
                    </div>

                    <!-- ORDER DESCRIPTION -->

                    <div class="card">
                        <div class="card-header">
                            <b-form inline>
                                {{ $i18n.tc('translations.Order', 1) }} {{ $i18n.tc('translations.Introduction', 1) }}
                                <template v-for="(action,actionIndex) in actions.updateDefaults">
                                    <ButtonAction :key="action.name+'-'+actionIndex" :action="action"
                                                  v-on:update-defaults="updateOrder"/>
                                </template>
                            </b-form>
                        </div>
                        <div class="card-body st-fadein-content" v-if="settings.defaults">
                            <p v-html="settings.defaults.order_introduction"/>
                        </div>
                        <div class="card-header">
                            {{ $i18n.tc('translations.Order', 1) }} {{ $i18n.tc('translations.Description', 1) }}
                        </div>
                        <div class="card-body st-fadein-content" v-if="settings.defaults">
                            <p v-html="settings.defaults.order_description"/>
                        </div>
                    </div>

                </div>
            </div>

        </div>

    </div>
</template>

<script>
import ButtonAction from "@/components/actions/ButtonAction";
import RecordDialog from "@/components/dialogs/RecordDialog";

export default {
    name: 'Settings',
    components: {
        ButtonAction,
        RecordDialog,
    },
    props: ['name', 'icon', 'api', 'state', 'userLanguage', 'languages'],
    data() {
        return {
            pageLoaded: false,
            actions: null,
            fields: null,
            settings: null,
            showRecordDialog: false,
            recordDialogParameters: {
                type: null,
                title: null,
                api: null,
                fields: null,
                data: null,
                languages: this.languages,
                userLanguage: this.userLanguage,
            },
        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.state.loading = true;
            this.pageLoaded = false;
            this.$http.get(this.api + '/manage', {}).then((res) => {
                this.actions = res.data.actions;
                this.fields = res.data.fields;
                this.settings = res.data.settings;
                this.state.loading = false;
                this.pageLoaded = true;
            }).catch((error) => {
                console.log("Settings:fetchData():error:", error);
            });
        },
        updateEstimate() {
            this.recordDialogParameters.type = "update";
            this.recordDialogParameters.title = this.$i18n.tc("translations.Estimate", 1);
            this.recordDialogParameters.api = this.api + '/update_defaults_estimate';
            this.recordDialogParameters.fields = this.fields.estimate;
            this.recordDialogParameters.data = this.settings.defaults;
            this.showRecordDialog = true;
        },
        updateOrder() {
            this.recordDialogParameters.type = "update";
            this.recordDialogParameters.title = this.$i18n.tc("translations.Order", 1);
            this.recordDialogParameters.api = this.api + '/update_defaults_order';
            this.recordDialogParameters.fields = this.fields.order;
            this.recordDialogParameters.data = this.settings.defaults;
            this.showRecordDialog = true;
        },
        updateParameters() {
            this.recordDialogParameters.type = "update";
            this.recordDialogParameters.title = this.$i18n.t("translations.Parameters");
            this.recordDialogParameters.api = this.api + '/update_parameters';
            this.recordDialogParameters.fields = this.fields.parameters;
            this.recordDialogParameters.data = this.settings.parameters;
            this.showRecordDialog = true;
        },
        updateQuote() {
            this.recordDialogParameters.type = "update";
            this.recordDialogParameters.title = this.$i18n.tc("translations.Quote", 1);
            this.recordDialogParameters.api = this.api + '/update_defaults_quote';
            this.recordDialogParameters.fields = this.fields.quote;
            this.recordDialogParameters.data = this.settings.defaults;
            this.showRecordDialog = true;
        },
        updateScheduler() {
            this.recordDialogParameters.type = "update";
            this.recordDialogParameters.title = this.$i18n.t("translations.Scheduler");
            this.recordDialogParameters.api = this.api + '/update_scheduler';
            this.recordDialogParameters.fields = this.fields.scheduler;
            this.recordDialogParameters.data = this.settings.scheduler;
            this.showRecordDialog = true;
        },
        updateSketchUp() {
            this.recordDialogParameters.type = "update";
            this.recordDialogParameters.title = this.$i18n.t("translations.SketchUp");
            this.recordDialogParameters.api = this.api + '/update_sketchup';
            this.recordDialogParameters.fields = this.fields.sketchUp;
            this.recordDialogParameters.data = this.settings.sketchUp;
            this.showRecordDialog = true;
        },
    }
}
</script>

<style scoped>
</style>
